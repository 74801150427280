<template>
  <div class="register-user-mgr-form-contianer">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <col2-item label="姓名" :text="form.userName" />
          <col2-item label="手机号" :text="form.mobileNum" />
          <col2-item label="性别" :text="sexMap[form.sex]" />
          <col2-item label="是否关联组织" :text="isRelateOrg" />
          <col2-item label="所属项目">
            <a class="link" @click="showOrgsModal" v-text="communityName"></a>
          </col2-item>
          <col2-item label="政治面貌" :text="form.politicsStatus" />
          <col2-item label="民族" :text="form.nation" />
          <col2-item label="籍贯" :text="form.nativePlace" />
          <col2-item label="婚姻状况" :text="maritalStatusMap[form.isMarry]" />
          <col2-item label="证件类型" :text="cardTypeMap[form.cardType]" />
          <col2-item label="证件号码" :text="form.cardNum" />
          <col2-item label="出生日期" :text="form.birthday" />
          <col2-item label="称谓" :text="form.alias" />
          <col2-item label="电话" :text="form.phoneNum" />
          <col2-item label="职业" :text="form.duty" />
          <col2-item label="邮件地址" :text="form.emailAddress" />
          <col2-item label="数据来源" :text="userSourceText" />
        </col2-block>
      </col2-detail>
    </form-panel>
    <el-dialog
      title="所属项目---关联组织"
      :visible.sync="orgsModalVisible">
      <el-row class="orgs-modal-item" v-for="(item, index) in orgInfoList" :key="index">
        <el-col :span="8" v-text="item.communityName"></el-col>
        <el-col :span="16" v-text="item.orgNames"></el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { userSourceMap, maritalStatusMap, cardTypeMap, sexMap } from './map'
import { getDetailURL } from './api'
import { Col2Block, Col2Detail, Col2Item } from '@/components/bussiness'

export default {
  name: 'registerUserMgrForm',
  components: {
    Col2Block,
    Col2Detail,
    Col2Item
  },
  data () {
    return {
      maritalStatusMap,
      cardTypeMap,
      sexMap,
      orgInfoList: [],
      queryUrl: getDetailURL,
      form: {
        userName: '',
        mobileNum: '',
        sex: 1
      },
      isRelateOrg: '',
      communityName: '',
      orgsModalVisible: false,
      userSourceText: ''
    }
  },
  mounted () {
    this.$refs.form.getData({ id: this.$route.query.id })
    this.$setBreadcrumb('详情')
  },
  methods: {
    update (data) {
      /* eslint-disable */
      [
        'id',
        'mobileNum',       // 手机号
        'userName',        // 姓名
        'sex',             // 性别
        'nation',          // 民族
        'duty',            // 职业
        'emailAddress',    // 邮件地址
        'politicsStatus',  // 政治面貌
        'nativePlace',     // 籍贯
        'alias',           // 称谓
        'birthday',        // 出生日期
        'cardNum',         // 证件号码
        'isMarry',         // 婚姻状况
        'cardType',        // 证件类型
        'phoneNum',        // 电话号码
        'userSource'       // 数据来源
      ].forEach(key => { 
        if (data[key]) {
          this.form[key] = data[key] 
        }
      })
      this.form.birthday = this.form.birthday && this.form.birthday.substr(0, 11) // 将生日载取到日期
      this.isRelateOrg = data.orgInfoList.length === 0 ? '否' : '是'
      this.communityName = data.orgInfoList.map(item => item.communityName).join(',')
      this.orgInfoList = data.orgInfoList.map(item => ({ ...item, orgNames: item.orgNames.join(',') }))
      this.userSourceText = userSourceMap[data.userSource]
    },
    showOrgsModal () {
      // 显示所属组织的弹框
      this.orgsModalVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .link {
    color: #1b8cf2;
    cursor: pointer;
  }
  .orgs-modal-item{
    text-align: left;
  }
</style>
