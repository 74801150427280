var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-user-mgr-form-contianer" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: { form: _vm.form, queryUrl: _vm.queryUrl },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c("col2-item", {
                    attrs: { label: "姓名", text: _vm.form.userName },
                  }),
                  _c("col2-item", {
                    attrs: { label: "手机号", text: _vm.form.mobileNum },
                  }),
                  _c("col2-item", {
                    attrs: { label: "性别", text: _vm.sexMap[_vm.form.sex] },
                  }),
                  _c("col2-item", {
                    attrs: { label: "是否关联组织", text: _vm.isRelateOrg },
                  }),
                  _c("col2-item", { attrs: { label: "所属项目" } }, [
                    _c("a", {
                      staticClass: "link",
                      domProps: { textContent: _vm._s(_vm.communityName) },
                      on: { click: _vm.showOrgsModal },
                    }),
                  ]),
                  _c("col2-item", {
                    attrs: { label: "政治面貌", text: _vm.form.politicsStatus },
                  }),
                  _c("col2-item", {
                    attrs: { label: "民族", text: _vm.form.nation },
                  }),
                  _c("col2-item", {
                    attrs: { label: "籍贯", text: _vm.form.nativePlace },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "婚姻状况",
                      text: _vm.maritalStatusMap[_vm.form.isMarry],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "证件类型",
                      text: _vm.cardTypeMap[_vm.form.cardType],
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "证件号码", text: _vm.form.cardNum },
                  }),
                  _c("col2-item", {
                    attrs: { label: "出生日期", text: _vm.form.birthday },
                  }),
                  _c("col2-item", {
                    attrs: { label: "称谓", text: _vm.form.alias },
                  }),
                  _c("col2-item", {
                    attrs: { label: "电话", text: _vm.form.phoneNum },
                  }),
                  _c("col2-item", {
                    attrs: { label: "职业", text: _vm.form.duty },
                  }),
                  _c("col2-item", {
                    attrs: { label: "邮件地址", text: _vm.form.emailAddress },
                  }),
                  _c("col2-item", {
                    attrs: { label: "数据来源", text: _vm.userSourceText },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "所属项目---关联组织",
            visible: _vm.orgsModalVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.orgsModalVisible = $event
            },
          },
        },
        _vm._l(_vm.orgInfoList, function (item, index) {
          return _c(
            "el-row",
            { key: index, staticClass: "orgs-modal-item" },
            [
              _c("el-col", {
                attrs: { span: 8 },
                domProps: { textContent: _vm._s(item.communityName) },
              }),
              _c("el-col", {
                attrs: { span: 16 },
                domProps: { textContent: _vm._s(item.orgNames) },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }